import React from 'react';
import { InputText } from "primereact/inputtext";
import { WidgetProps } from '../WidgetProperties'; // Import the WidgetProps interface

// Define specific type for the value of LinkWidget
export interface LinkWidgetValue {
	displayedText: string;
	link: string;
}

// Use WidgetProps with the specific value type
const LinkWidget: React.FC<WidgetProps<LinkWidgetValue>> = ({ value, onChange }) => {
	return (
		<div>
			<InputText
				placeholder="Displayed Text"
				value={value.displayedText || ''}
				onChange={(e) => onChange('displayedText', e.target.value)}
			/>
			<InputText
				placeholder="Link"
				value={value.link || ''}
				onChange={(e) => onChange('link', e.target.value)}
			/>
		</div>
	);
};

export default LinkWidget;
