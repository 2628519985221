import React from 'react';
import Profile from "./Pages/Profile/Profile";
import Entry from "./Pages/Entry/Entry";
import Home from "./Pages/Home/Home";

import 'primereact/resources/themes/lara-dark-purple/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './App.css'

import {Navigate, Route, BrowserRouter as Router, Routes} from "react-router-dom";
import EmailVerification from "./Pages/EmailVerification/EmailVerification";

const App: React.FC = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Navigate to="/login" />} />
				<Route path="/login" element={<Entry />} />
				<Route path="/home" element={<Home />} />
				<Route path="/p/:username" element={<Profile />} />
				<Route path="/verify_email/:verification_token" element={<EmailVerification />} />
			</Routes>
		</Router>
	);
};

export default App;