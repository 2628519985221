import React from 'react';

import '../Widget.css'
import './TextWidget.css'

interface TextWidgetProps {
	text: string,
}

const TextWidget : React.FC<TextWidgetProps> = ({text}) => {
	return (
		<div className="widget">
			<div className="widget-content row">
				<div className="col-1 p-0 d-flex justify-content-center align-items-center">
					<i
						className="pi pi-circle-on icon m-0"
						style={{fontSize: '12px'}}
					/>
				</div>
				<div className="col-11 p-0 d-flex align-items-center">
					<span className="content">{text}</span>
				</div>
			</div>
		</div>
	);
};

export default TextWidget;