import React from 'react';
import './EditProfileFooter.css';
import { Button } from 'primereact/button';

interface EditProfileFooterProps {
	onSave: () => void;
}

const EditProfileFooter: React.FC<EditProfileFooterProps> = ({ onSave }) => {
	return (
		<div className="edit-profile-footer">
			<Button className="footer-button save-button" onClick={onSave}>Save</Button>
			{/*<Button className="footer-button preview-button">Preview</Button>*/}
		</div>
	);
};

export default EditProfileFooter;
