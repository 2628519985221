import React, {useEffect, useState} from 'react';
import './EmailVerification.css'
import {useParams} from "react-router-dom";
import axios from "axios";

const EmailVerification = () => {
	const { verification_token } = useParams<{ verification_token: string }>();
	const [isEmailVerified, setIsEmailVerified] = useState<string>('');
	const [error, setError] = useState<string>('');

	useEffect(() => {
		const verifyEmail = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/verify/${verification_token}`);
				if (response.status === 200) {
					setIsEmailVerified('Email verified successfully.');
				} else {
					setError('Verification failed. Please try again.');
				}
			} catch (err) {
				setError('Verification failed. Please try again.');
			}
		};

		if (verification_token) {
			verifyEmail();
		}
	}, [verification_token]);


	return (
		<div className={"email-verification"}>
			{isEmailVerified ? (
				<p>{isEmailVerified}</p>
			) : (
				<p className="error">{error}</p>
			)}
		</div>
	);
};

export default EmailVerification;