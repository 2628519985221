import React from 'react';

import {SortableTree} from './SortableTree';
import {TreeItems} from "./types";

interface DNDWidgetProps {
  items: TreeItems;
  setItems: React.Dispatch<React.SetStateAction<TreeItems>>;
}

const Wrapper = ({children}: {children: React.ReactNode}) => (
  <div
    style={{
      maxWidth: 450,
      padding: 10,
      margin: '0 auto',
      marginTop: '10%',
    }}
  >
    {children}
  </div>
);

export const DNDWidget: React.FC<DNDWidgetProps> = ({ items, setItems }) => (
  <Wrapper>
    <SortableTree
      collapsible
      indicator
      removable
      items={items} // Pass items to SortableTree
      setItems={setItems} // Pass setItems to SortableTree
    />
  </Wrapper>
);

// export const BasicSetup = () => (
//   <Wrapper>
//     <SortableTree />
//   </Wrapper>
// );
//
// export const DropIndicator = () => (
//   <Wrapper>
//     <SortableTree indicator />
//   </Wrapper>
// );
//
// export const Collapsible = () => (
//   <Wrapper>
//     <SortableTree collapsible />
//   </Wrapper>
// );
//
// export const RemovableItems = () => (
//   <Wrapper>
//     <SortableTree removable />
//   </Wrapper>
// );
