import React from 'react';

interface EmailWidgetProps {
	email: string;
}

const EmailWidget : React.FC<EmailWidgetProps> = ({email}) => {

	return (
		<div className="widget">
			<div className="widget-content row">
				<div className="col-1 p-0 d-flex justify-content-center align-items-center">
					<i
						className="pi pi-envelope icon m-0"
						style={{fontSize: '15px'}}
					/>
				</div>
				<div className="col-11 p-0 d-flex align-items-center">
					<span className="content"><a href={`mailto:${email}`}>{email}</a></span>
				</div>
			</div>
		</div>
	);
};

export default EmailWidget;