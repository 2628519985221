import React from 'react';
import { InputText } from "primereact/inputtext";
import { WidgetProps } from '../WidgetProperties'; // Import the WidgetProps interface

// Define specific type for the value of HeaderWidget
export interface HeaderWidgetValue {
	headerText: string;
}

// Use WidgetProps with the specific value type
const HeaderWidget: React.FC<WidgetProps<HeaderWidgetValue>> = ({ value, onChange }) => {
	return (
		<div>
			<InputText
				placeholder="Header"
				value={value.headerText || ''}
				onChange={(e) => onChange('headerText', e.target.value)}
			/>
		</div>
	);
};

export default HeaderWidget;
