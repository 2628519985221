import React from 'react';
import { FileUpload } from 'primereact/fileupload';
import { WidgetProps } from '../WidgetProperties'; // Import the WidgetProps interface

// Define specific type for the value of FileWidget
export interface FileWidgetValue {
	files: any; // You can replace 'any' with a more specific type like File[] if you know the structure
}

// Use WidgetProps with the specific value type
const FileWidget: React.FC<WidgetProps<FileWidgetValue>> = ({ value, onChange }) => {
	const handleFileUpload = (e: { files: any }) => {
		const files = e.files;
		onChange('files', files); // Pass the uploaded files to the parent component
	};

	return (
		<div>
			<FileUpload
				name="demo[]"
				url="./upload"
				multiple
				accept="*/*"
				maxFileSize={1000000}
				onUpload={handleFileUpload}
				customUpload
				auto={false}
			/>
		</div>
	);
};

export default FileWidget;
