import React from 'react';
import { InputText } from "primereact/inputtext";
import { WidgetProps } from '../WidgetProperties'; // Import the WidgetProps interface

// Define specific type for the value of MobileWidget
export interface MobileWidgetValue {
	mobileNumber: string;
	/*countryCode: string;*/
}

// Use WidgetProps with the specific value type
const MobileWidget: React.FC<WidgetProps<MobileWidgetValue>> = ({ value, onChange }) => {
	return (
		<div>
			<InputText
				placeholder="Mobile Number"
				value={value.mobileNumber || ''}
				onChange={(e) => onChange('mobileNumber', e.target.value)}
			/>
			{/*<InputText
				placeholder="Country Code"
				value={value.countryCode || ''}
				onChange={(e) => onChange('countryCode', e.target.value)}
			/>*/}
		</div>
	);
};

export default MobileWidget;
