import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TabMenu from '../../Components/TabMenu/TabMenu';
import EditProfileFooter from './EditProfileFooter/EditProfileFooter';
import PrimaryContent from './PrimaryContent/PrimaryContent';
import WidgetsContent from './WidgetsContent/WidgetsContent';
import StyleContent from './StyleContent/StyleContent';
import AdvancedContent from './AdvancedContent/AdvancedContent';
import './EditProfile.css';
import {TreeItem, TreeItems} from '../../Components/Tree/types';
import {AdvancedSettings, PrimarySettings, Style, UserProfile} from "../Home/Home";

interface EditProfileProps {
	profile?: UserProfile | null;
	userID: string | null;
}

const EditProfile: React.FC<EditProfileProps> = ({ profile, userID }) => {
	// State for user profile
	const [userProfile, setUserProfile] = useState<UserProfile | null>(profile || null);

	const [primarySettings, setPrimarySettings] = useState<PrimarySettings | undefined>(profile?.primarySettings);
	const [items, setItems] = useState<TreeItems | undefined>(profile?.widget);
	const [style, setStyle] = useState<Style | undefined>(profile?.style);
	const [advancedSettings, setAdvancedSettings] = useState<AdvancedSettings | undefined>(profile?.advancedSettings);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	// Handle save action using axios.put
	/*const handleSave = async () => {
		if (!userProfile || !userID) return;

		const data = {
			primarySettings: primarySettings,
			widget: items,
			style: style,
			advancedSettings: advancedSettings,
		};

		console.log(data)

		try {
			const response = await axios.put(`${process.env.REACT_APP_API_URL}/profile/${userID}`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			console.log('Profile saved successfully:', response.data);
		} catch (error) {
			console.error('Error saving profile:', error);
		}
	};*/

	const handleSave = async () => {
		if (!userProfile || !userID) return;

		const formData = new FormData();

		if (!primarySettings) {
			console.error("primarySettings undefined.");
			return;
		}

		formData.append('name', primarySettings.name);
		formData.append('designation', primarySettings.designation);
		formData.append('bio', primarySettings.bio);
		formData.append('mobile', primarySettings.mobile);
		formData.append('email', primarySettings.email);
		formData.append('industry', Array.isArray(primarySettings.industry) ? primarySettings.industry.join(',') : primarySettings.industry);
		formData.append('profileLink', primarySettings.profileLink);

		// If profilePictureFile exists, append it to FormData
		if (primarySettings.profilePictureFile) {
			formData.append('profilePictureFile', primarySettings.profilePictureFile);
		} else {
			formData.append('profilePicturePath', primarySettings.profilePicturePath || '');
		}

		// Append JSON objects as strings
		formData.append('widget', JSON.stringify(items));
		formData.append('style', JSON.stringify(style));
		formData.append('advancedSettings', JSON.stringify(advancedSettings));

		// Debugging: log the FormData contents
		for (let [key, value] of formData.entries()) {
			console.log(`${key}: ${value}`);
		}

		try {
			const response = await axios.put(`${process.env.REACT_APP_API_URL}/profile/${userID}`, formData);
			console.log('Profile saved successfully:', response.data);
		} catch (error) {
			console.error('Error saving profile:', error);
		}
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>{error}</div>;
	}

	if (!userProfile) {
		return <div>No profile data available.</div>;
	}

	// Tab menu content with various sections
	const tabsContent: Record<string, React.ReactNode> = {
		Primary: (
			<PrimaryContent
				/*userID={userID}
				primarySettings={userProfile.primarySettings}
				setPrimarySettings={(newPrimarySettings) =>
					setUserProfile((prev) =>
						prev
							? {
								...prev,
								primarySettings: newPrimarySettings as PrimarySettings,
							}
							: null
					)
				}*/
				// @ts-ignore
				primarySettings={primarySettings}
				// @ts-ignore
				setPrimarySettings={setPrimarySettings}
			 	userID={userID}/>
		),
		Widgets: (
			<WidgetsContent
				/*items={userProfile.widget}
				setItems={(newItems) =>
					setUserProfile((prev) =>
						prev
							? {
								...prev,
								widget: newItems as TreeItem[],
							}
							: null
					)
				}*/
				// @ts-ignore
				items={items}
				// @ts-ignore
				setItems={setItems}
			/>
		),
		/*Style: (
			<StyleContent
				bannerColors={userProfile.style.bannerColors}
				setBannerColors={(newBannerColors) =>
					setUserProfile((prev) =>
						prev
							? {
								...prev,
								style: {
									...prev.style,
									bannerColors: newBannerColors as BannerColors,
								},
							}
							: null
					)
				}
				backgroundColors={userProfile.style.backgroundColors}
				setBackgroundColors={(newBackgroundColors) =>
					setUserProfile((prev) =>
						prev
							? {
								...prev,
								style: {
									...prev.style,
									backgroundColors: newBackgroundColors as BackgroundColors,
								},
							}
							: null
					)
				}
				accentColors={userProfile.style.accentColors}
				setAccentColors={(newAccentColors) =>
					setUserProfile((prev) =>
						prev
							? {
								...prev,
								style: {
									...prev.style,
									accentColors: newAccentColors as AccentColors,
								},
							}
							: null
					)
				}
			/>
		),*/
		/*Advanced: (
			<AdvancedContent
				/!*advancedSettings={userProfile.advancedSettings}
				setAdvancedSettings={(newAdvancedSettings) =>
					setUserProfile((prev) =>
						prev
							? {
								...prev,
								advancedSettings: newAdvancedSettings as AdvancedSettings,
							}
							: null
					)
				}*!/
				// @ts-ignore
				advancedSettings={advancedSettings}
				// @ts-ignore
				setAdvancedSettings={setAdvancedSettings}
			/>
		),*/
	};


	return (
		<div className="edit-profile-container">
			<TabMenu tabsContent={tabsContent} />
			<EditProfileFooter onSave={handleSave} />
		</div>
	);
};

export default EditProfile;
