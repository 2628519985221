import React, { useEffect, useState } from 'react';
import './Profile.css';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ShareIcon from '@mui/icons-material/Share';

import Logo from '../../Assets/Logo/xCard_gradientSilver.png';
import DefaultBanner from '../../Assets/Profile/DefaultBanner.png';

import { Button } from 'primereact/button';
import HeaderWidget from '../../Components/Widgets/HeaderWidget';
import { CompanyWidget, EmailWidget, LinkWidget, MobileWidget, SocialWidget, TextWidget } from '../../Components/Widgets';
import { useParams } from "react-router-dom";
import axios from "axios";
import { UserProfile } from "../Home/Home";

interface PrimarySettings {
	profilePictureFile: File | string | null;
	profilePicturePath: string | null;
	name: string;
	designation: string;
	bio: string;
	mobile: string;
	email: string;
	industry: string[];
	profileLink: string;
}

interface TreeItem {
	id: string;
	type: string;
	children: TreeItem[];
	data: Record<string, any>;
}

const Profile: React.FC = () => {
	const { username } = useParams<{ username: string }>();
	const [profile, setProfile] = useState<UserProfile | undefined>(undefined);
	const [primarySettings, setPrimarySettings] = useState<PrimarySettings | undefined>(undefined);
	const [items, setItems] = useState<TreeItem[]>([]);

	// Fetch the user profile when the component mounts
	useEffect(() => {
		const fetchProfile = async () => {
			try {
				// Fetch the userID based on the username
				const userIDResponse = await axios.get(`${process.env.REACT_APP_API_URL}/userID/${username}`);
				const userID = userIDResponse.data.data;

				// Fetch the profile using the obtained userID
				const profileResponse = await axios.get(
					`${process.env.REACT_APP_API_URL}/profile/${userID}`
				);

				setProfile(profileResponse.data.data);
				setPrimarySettings(profileResponse.data.data.primarySettings);
				setItems(profileResponse.data.data.widget);

				console.log(profileResponse.data.data.widget);

			} catch (err) {
				console.error('Error fetching profile:', err);
			}
		};

		fetchProfile();
	}, [username]);

	const getProfilePictureSrc = (): string | null => {
		if (primarySettings && primarySettings.profilePicturePath) {
			return primarySettings.profilePicturePath;
		}

		return null;
	};

	const renderWidget = (item: TreeItem) => {
		switch (item.type) {
			case 'Header':
				return <HeaderWidget key={item.id} headerText={item.data.headerText}/>;
			case 'Text':
				return <TextWidget key={item.id} text={item.data.text}/>;
			case 'Company':
				return <CompanyWidget key={item.id} companyName={item.data.companyName} />;
			case 'Email':
				return <EmailWidget key={item.id} email={item.data.email} />;
			case 'Mobile':
				return <MobileWidget key={item.id} mobileNumber={item.data.mobileNumber} />;
			case 'Link':
				return <LinkWidget key={item.id} displayedText={item.data.displayedText} link={item.data.link}/>;
			case 'Container':
				return (
					<div key={item.id} className="container">
						{item.children.map(renderWidget)}
					</div>
				);
			default:
				return null;
		}
	};

	if (!primarySettings) {
		return <div>Loading profile...</div>; // Display a loading state while fetching data
	}

	return (
		<div className="profile-container">
			<div className="profile">
				<div className="primary">
					<div className="banner" style={{backgroundImage: `url(${DefaultBanner})`}}></div>
					<div className="avatar">
						<img src={getProfilePictureSrc() || DefaultBanner} alt="Profile Avatar" className="avatar-image" />
					</div>
					<h2 className="name">{primarySettings.name}</h2>
					<h4 className="designation">{primarySettings.designation}</h4>
					<p className="bio">
						{primarySettings.bio}
					</p>
					<div className="actions">
						<button className="action-btn phone-btn">
							<a href={`tel:${primarySettings.mobile}`}><PhoneIcon style={{ width: '30px', height: '30px' }} /></a>
						</button>
						<button className="action-btn email-btn">
							<a href={`mailto:${primarySettings.email}`}><EmailIcon style={{width: '30px', height: '30px'}} /></a>
						</button>
						{/*<button className="action-btn share-btn">
							<ShareIcon style={{ width: '30px', height: '30px' }} />
						</button>*/}
					</div>
				</div>
				<div className="widgets">
					{items.map(renderWidget)}
				</div>
				<div className="footer">
					{/*<Button label="Save Contact" className="p-button-primary" />*/}
					<img 
						className="logo" 
						src={Logo} 
						alt="XCard Logo" 
						onClick={() => window.location.href = "https://xcard.webxite.my"}/>
				</div>
			</div>
		</div>
	);
};

export default Profile;
