import React from 'react';

interface LinkWidgetProps {
	displayedText: string;
	link: string;
}

const LinkWidget:React.FC<LinkWidgetProps> = ({displayedText, link}) => {

	const modifiedLink = 'https://' + link;
	
	return (
		<div className="widget">
			<div className="widget-content row">
				<div className="col-1 p-0 d-flex justify-content-center align-items-center">
					<i
						className="pi pi-link icon m-0"
						style={{fontSize: '15px'}}
					/>
				</div>
				<div className="col-11 p-0 d-flex align-items-center">
					<span className="content"><a href={modifiedLink}>{displayedText}</a></span>
				</div>
			</div>
		</div>
	);
};

export default LinkWidget;