import React from 'react';
import { InputText } from "primereact/inputtext";
import { WidgetProps } from '../WidgetProperties'; // Import the WidgetProps interface

// Define specific type for the value of EmailWidget
export interface EmailWidgetValue {
	email: string;
}

// Use WidgetProps with the specific value type
const EmailWidget: React.FC<WidgetProps<EmailWidgetValue>> = ({ value, onChange }) => {
	return (
		<div>
			<InputText
				placeholder="Email"
				value={value.email || ''}
				onChange={(e) => onChange('email', e.target.value)}
			/>
		</div>
	);
};

export default EmailWidget;
