import React, { useState, ChangeEvent, FormEvent, useRef } from 'react';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from '@mui/material';
import axios from 'axios';

import IphoneMockup from '../../Assets/Entry/IphoneMockup.png';
import Logo from '../../Assets/Logo/xCard_gradientSilver.png';

import './Entry.css';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';

// Define TypeScript interface for API response
interface ApiResponse {
	status: string;
	data: {
		token: string;
		userID: string;
	};
}

const Entry = () => {
	const [isSignup, setIsSignup] = useState(false);
	const [formData, setFormData] = useState({
		signupUsername: '',
		signupEmail: '',
		signupPassword: '',
		confirmPassword: '',
		signupTerms: false,
		loginUsernameOrEmail: '',
		loginPassword: '',
	});

	const [errors, setErrors] = useState({
		signupUsername: '',
		signupEmail: '',
		signupPassword: '',
		confirmPassword: '',
		loginUsernameOrEmail: '',
		loginPassword: '',
	});

	const toast = useRef<Toast>(null);
	const navigate = useNavigate();

	// Error handling function to display Toast messages
	const showError = (message: number | string) => {
		toast.current?.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
	};

	const showSuccess = (message: string, sticky: boolean) => {
		toast.current?.show({ severity: 'success', summary: 'Success', detail: message, life: 3000, sticky: sticky});
	};

	// Toggle between signup and login forms, resetting the form data
	const toggleForm = () => {
		setIsSignup(!isSignup);
		setFormData({
			signupUsername: '',
			signupEmail: '',
			signupPassword: '',
			confirmPassword: '',
			signupTerms: false,
			loginUsernameOrEmail: '',
			loginPassword: '',
		});
		setErrors({
			signupUsername: '',
			signupEmail: '',
			signupPassword: '',
			confirmPassword: '',
			loginUsernameOrEmail: '',
			loginPassword: '',
		});
	};

	// Real-time validation functions
	const validateEmail = (email: string) => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(email);
	};

	const validatePassword = (password: string) => {
		return password.length >= 6; // Example: password must be at least 6 characters
	};
	
	const validateConfirmPassword = (password: string) => {
		return password === formData.signupPassword;
	}

	// Individual input change handlers with real-time validation
	const handleSignupUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setFormData((prev) => ({ ...prev, signupUsername: value }));
		setErrors((prev) => ({ ...prev, signupUsername: value ? '' : 'Username is required.' }));
	};

	const handleSignupEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setFormData((prev) => ({ ...prev, signupEmail: value }));
		setErrors((prev) => ({
			...prev,
			signupEmail: validateEmail(value) ? '' : 'Enter a valid email address.',
		}));
	};

	const handleSignupPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setFormData((prev) => ({ ...prev, signupPassword: value }));
		setErrors((prev) => ({
			...prev,
			signupPassword: validatePassword(value) ? '' : 'Password must be at least 6 characters.',
		}));
	};

	const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setFormData((prev) => ({ ...prev, confirmPassword: value }));
		setErrors((prev) => ({
			...prev,
			confirmPassword: validateConfirmPassword(value) ? '' : 'Password is not the same as the above.',
		}));
	};

	const handleSignupTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFormData((prev) => ({ ...prev, signupTerms: e.target.checked }));
	};

	const handleLoginUsernameOrEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setFormData((prev) => ({ ...prev, loginUsernameOrEmail: value }));
		setErrors((prev) => ({
			...prev,
			loginUsernameOrEmail: value ? '' : 'Username or Email is required.',
		}));
	};

	const handleLoginPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setFormData((prev) => ({ ...prev, loginPassword: value }));
		setErrors((prev) => ({
			...prev,
			loginPassword: validatePassword(value) ? '' : 'Password must be at least 6 characters.',
		}));
	};

	// Check if the form is valid based on current form data
	const isFormValid = () => {
		if (isSignup) {
			// Signup validation
			return (
				formData.signupUsername &&
				validateEmail(formData.signupEmail) &&
				validatePassword(formData.signupPassword) &&
				formData.signupTerms
			);
		} else {
			// Login validation
			return formData.loginUsernameOrEmail && validatePassword(formData.loginPassword);
		}
	};

	// Handle form submission, including API call and error handling
	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			const endpoint = isSignup
				? `${process.env.REACT_APP_API_URL}/auth/signup`
				: `${process.env.REACT_APP_API_URL}/auth/login`;
			const data = isSignup
				? {
					username: formData.signupUsername,
					email: formData.signupEmail,
					password: formData.signupPassword,
				}
				: {
					usernameOrEmail: formData.loginUsernameOrEmail,
					password: formData.loginPassword,
				};

			// Perform the API request with proper typing
			const response = await axios.post<ApiResponse>(endpoint, data);

			// Correctly handle the API response based on the data status
			if (response.data && response.data.status === 'success') {
				if (!isSignup) {
					const {token, userID} = response.data.data;
					navigate('/home', { replace: true, state: { token, userID } });
				} else {
					const successMessage = "Sign Up Successful."
					const activateAccountMessage = `Please check your email at ${formData.signupEmail} to activate your account.` +
					" The link is valid for 24 hours"
					
					// Navigate to log-in form
					setIsSignup(!isSignup);
					
					// Show success message and sticky activate account prompt to users
					showSuccess(successMessage, false);
					showSuccess(activateAccountMessage, true);
				}

				// Initialize all form fields
				setFormData({
					signupUsername: '',
					signupEmail: '',
					signupPassword: '',
					confirmPassword: '',
					signupTerms: false,
					loginUsernameOrEmail: '',
					loginPassword: '',
				});
			} else {
				showError(response.data.status || 'An error occurred.');
			}
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				showError(error.response.data?.status || 'An unexpected error occurred.');
			} else {
				showError('An unexpected error occurred. Please try again.');
			}
			console.error('API call error:', error);
		}
	};

	return (
		<div className="entry-container">
			<Toast ref={toast} position={"top-center"}/>
			<div className="entry d-flex">
				<div className="entry-form">
					<div className="logo">
						<img src={Logo} alt="Xcard Logo" />
					</div>
					<h2>{isSignup ? 'Sign Up' : 'Login'}</h2>
					<p>
						{isSignup
							? 'Activate now and start building stronger connections today'
							: 'Welcome back! Stay connected with others.'}
					</p>

					<form onSubmit={handleSubmit}>
						{isSignup ? (
							<>
								<div className={`input-field ${errors.signupUsername ? 'has-error' : ''}`}>
									<InputText
										id="signupUsername"
										name="signupUsername"
										value={formData.signupUsername}
										onChange={handleSignupUsernameChange}
										placeholder="Username"
									/>
									{errors.signupUsername && <p className="error-message">{errors.signupUsername}</p>}
								</div>

								<div className={`input-field ${errors.signupEmail ? 'has-error' : ''}`}>
									<InputText
										id="signupEmail"
										name="signupEmail"
										value={formData.signupEmail}
										onChange={handleSignupEmailChange}
										placeholder="Email"
									/>
									{errors.signupEmail && <p className="error-message">{errors.signupEmail}</p>}
								</div>

								<div className={`input-field ${errors.signupPassword ? 'has-error' : ''}`}>
									<Password
										id="signupPassword"
										name="signupPassword"
										value={formData.signupPassword}
										onChange={handleSignupPasswordChange}
										placeholder="Password"
										feedback={false}
									/>
									{errors.signupPassword && <p className="error-message">{errors.signupPassword}</p>}
								</div>

								<div className={`input-field ${errors.confirmPassword ? 'has-error' : ''}`}>
									<Password
										id="confirmPassword"
										name="confirmPassword"
										value={formData.confirmPassword}
										onChange={handleConfirmPasswordChange}
										placeholder="Confirm Password"
										feedback={false}
									/>
									{errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
								</div>

								<div className="p-field-checkbox d-flex align-items-center">
									<Checkbox
										id="signupTerms"
										name="signupTerms"
										checked={formData.signupTerms}
										onChange={handleSignupTermsChange}
										sx={{
											color: '#D9D9D9',
											'&.Mui-checked': {
												color: '#068FFF',
											},
										}}
									/>
									<label htmlFor="signupTerms">
										I understand and agree to the <a href="#">Privacy Policy</a> and{' '}
										<a href="#">Terms of Service</a>.
									</label>
								</div>

								<Button type="submit" label="Sign Up" className="p-button-primary" disabled={!isFormValid()} />
								<p className="toggle-link">
									Already have an account?{' '}
									<a href="#" onClick={toggleForm}>
										Login
									</a>
								</p>
							</>
						) : (
							<>
								<div className={`input-field ${errors.loginUsernameOrEmail ? 'has-error' : ''}`}>
									<InputText
										id="loginUsernameOrEmail"
										name="loginUsernameOrEmail"
										value={formData.loginUsernameOrEmail}
										onChange={handleLoginUsernameOrEmailChange}
										placeholder="Username/Email"
									/>
									{errors.loginUsernameOrEmail && (
										<p className="error-message">{errors.loginUsernameOrEmail}</p>
									)}
								</div>

								<div className={`input-field ${errors.loginPassword ? 'has-error' : ''}`}>
									<Password
										id="loginPassword"
										name="loginPassword"
										value={formData.loginPassword}
										onChange={handleLoginPasswordChange}
										placeholder="Password"
										feedback={false}
									/>
									{errors.loginPassword && <p className="error-message">{errors.loginPassword}</p>}
								</div>

								{/*<p className="toggle-link forget-password">
									<a href="#">Forget Password?</a>
								</p>*/}
								<Button type="submit" label="Login" className="p-button-primary" disabled={!isFormValid()} />
								<p className="toggle-link">
									Not registered?{' '}
									<a href="#" onClick={toggleForm}>
										Sign up now
									</a>
								</p>
							</>
						)}
					</form>
				</div>
				<div className="mockup">
					<img src={IphoneMockup} alt="Iphone Mockup" className="mockup-image" />
				</div>
			</div>
		</div>
	);
};

export default Entry;
