import React from 'react';
import { InputText } from "primereact/inputtext";
import { WidgetProps } from '../WidgetProperties'; // Import the WidgetProps interface

// Define specific type for the value of MapWidget
export interface MapWidgetValue {
	address: string;
	mapLink: string;
}

// Use WidgetProps with the specific value type
const MapWidget: React.FC<WidgetProps<MapWidgetValue>> = ({ value, onChange }) => {
	return (
		<div>
			<InputText
				placeholder="Address"
				value={value.address || ''}
				onChange={(e) => onChange('address', e.target.value)}
			/>
			<InputText
				placeholder="Google Map Location Link"
				value={value.mapLink || ''}
				onChange={(e) => onChange('mapLink', e.target.value)}
			/>
		</div>
	);
};

export default MapWidget;
