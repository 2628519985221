import React from 'react';
import { InputText } from "primereact/inputtext";
import { WidgetProps } from '../WidgetProperties'; // Import the WidgetProps interface

// Define specific type for the value of TextWidget
export interface TextWidgetValue {
	text: string;
	/*fontSize: string;*/
}

// Use WidgetProps with the specific value type
const TextWidget: React.FC<WidgetProps<TextWidgetValue>> = ({ value, onChange }) => {
	return (
		<div>
			<InputText
				placeholder="Text"
				value={value.text || ''}
				onChange={(e) => onChange('text', e.target.value)}
			/>
			{/*<InputText
				placeholder="Font Size"
				value={value.fontSize || ''}
				onChange={(e) => onChange('fontSize', e.target.value)}
			/>*/}
		</div>
	);
};

export default TextWidget;
