import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import './Home.css';
import EditProfile from "../EditProfile/EditProfile";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import {TreeItem, TreeItems} from "../../Components/Tree/types";

// Define TypeScript interfaces for location state and profile
interface LocationState {
	token: string;
	userID: string;
}

export interface PrimarySettings {
	profilePictureFile: File | null;
	profilePicturePath: string | null;
	name: string;
	designation: string;
	bio: string;
	mobile: string;
	email: string;
	industry: string | string[];
	profileLink: string;
}

export interface BannerColors {
	activeBannerColor: string;
	bannerColor1: string;
	bannerColor2: string;
	bannerColor3: string;
	bannerColor4: string;
}

export interface BackgroundColors {
	activeBackgroundColor: string;
	backgroundColor1: string;
	backgroundColor2: string;
	backgroundColor3: string;
	backgroundColor4: string;
}

export interface AccentColors {
	activeAccentColor: string;
	accentColor1: string;
	accentColor2: string;
	accentColor3: string;
	accentColor4: string;
	accentColor5: string;
}

export interface Style {
	bannerColors: BannerColors;
	backgroundColors: BackgroundColors;
	accentColors: AccentColors;
}

export interface AdvancedSettings {
	saveContactPosition: 'top' | 'bottom';
	profilePrivacy: boolean;
	showIndustry: boolean;
	showXConnect: boolean;
	contactSaveData: 'profile' | 'custom';
}

export interface UserProfile {
	primarySettings: PrimarySettings;
	widget: TreeItem[];
	style: Style;
	advancedSettings: AdvancedSettings;
}

const Home = () => {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [sidebarVisible, setSidebarVisible] = useState(!isMobile);
	const [profile, setProfile] = useState<UserProfile>();
	const [error, setError] = useState<string | null>(null);

	const location = useLocation();
	const navigate = useNavigate();

	const locationState = location.state as LocationState | null;
	const token = locationState?.token || null;
	const userID = locationState?.userID || null;

	useEffect(() => {
		const handleResize = () => {
			const mobile = window.innerWidth < 768;
			setIsMobile(mobile);
			setSidebarVisible(!mobile);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// Fetch the user profile when the component mounts
	useEffect(() => {
		const fetchProfile = async () => {
			if (!token || !userID) {
				navigate('/login', { replace: true });
				return;
			}

			try {
				const response = await axios.get<{ status: string; data: UserProfile }>(
					`${process.env.REACT_APP_API_URL}/profile/${userID}`,
					{
						headers: {
							Authorization: `Bearer ${token}`, 
						},
					}
				);

				console.log('User Profile:', response.data.data);
				setProfile(response.data.data);
			} catch (err) {
				console.error('Error fetching profile:', err);
				setError('Failed to load profile data.');
			}
		};

		fetchProfile();
	}, [token, userID, navigate]);

	const handleLogout = () => {
		navigate('/login', { replace: true });
		window.location.reload();
	};

	return (
		<div className="home-container">
			{isMobile && (
				<div className="top-bar">
					<Button
						icon="pi pi-bars"
						className="hamburger-button"
						onClick={() => setSidebarVisible(true)}
					/>
					<div
						className="profile-image-container"
						style={{ display: sidebarVisible ? 'none' : 'block' }}
					>
						<img src={profile?.primarySettings.profilePicturePath || "default_profile_picture_path.jpg"} alt="Profile" />
					</div>
				</div>
			)}

			<Sidebar
				visible={sidebarVisible}
				position="left"
				showCloseIcon={isMobile}
				onHide={() => setSidebarVisible(false)}
				className="custom-sidebar"
				modal={isMobile}
				dismissable={isMobile}
			>
				<div className="sidebar-content">
					<div className="profile-image-container">
						<img src={profile?.primarySettings.profilePicturePath || "default_profile_picture_path.jpg"} alt="Profile" />
					</div>
					<h4 className="username">
						Hey, {profile ? profile.primarySettings.name : '<Username>'}
					</h4>
					<div className="button-list">
						<Button label="Profile" className="menu-button" />
						{/*<Button label="Connections" className="menu-button" />*/}
						{/*<Button label="Analytics" className="menu-button" />*/}
						{/*<Button label="Settings" className="menu-button" />*/}
						{/*<Button label="More" className="menu-button" />*/}
					</div>
				</div>
				<div className="sidebar-footer">
					<Button
						label="Logout"
						icon="pi pi-sign-out"
						className="logout-button"
						onClick={handleLogout}
					/>
				</div>
			</Sidebar>

			<div className="main-content d-flex justify-content-center align-items-center">
				{error ? (
					<p className="error-message">{error}</p>
				) : (
					profile && <EditProfile profile={profile} userID={userID} />
				)}
			</div>
		</div>
	);
};

export default Home;
