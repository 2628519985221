import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Chips, ChipsChangeEvent } from 'primereact/chips';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import axios from 'axios';
import './PrimaryContent.css';
import { PrimarySettings } from '../../Home/Home';

interface PrimaryContentProps {
	primarySettings: PrimarySettings;
	setPrimarySettings: React.Dispatch<React.SetStateAction<PrimarySettings>>;
	userID: string | null;
}

const PrimaryContent: React.FC<PrimaryContentProps> = ({ primarySettings, setPrimarySettings, userID }) => {
	const [username, setUsername] = useState<string>('');
	const [imagePreview, setImagePreview] = useState<string | null>(primarySettings.profilePicturePath || null);

	// Correct initialization of localIndustry to handle array or empty string
	const [localIndustry, setLocalIndustry] = useState<string[]>(
		Array.isArray(primarySettings.industry)
			? primarySettings.industry
			: primarySettings.industry.trim() !== ''
				? primarySettings.industry.split(',')
				: []
	);

	// Local state to hold other form values temporarily
	const [localName, setLocalName] = useState<string>(primarySettings.name || '');
	const [localDesignation, setLocalDesignation] = useState<string>(primarySettings.designation || '');
	const [localBio, setLocalBio] = useState<string>(primarySettings.bio || '');
	const [localMobile, setLocalMobile] = useState<string>(primarySettings.mobile || '');
	const [localEmail, setLocalEmail] = useState<string>(primarySettings.email || '');

	useEffect(() => {
		// Fetch username from backend using userID
		const fetchUsername = async () => {
			if (userID) {
				try {
					const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${userID}`);
					setUsername(response.data.data.username);
				} catch (error) {
					console.error('Error fetching user details:', error);
				}
			}
		};

		fetchUsername();
	}, [userID]);

	useEffect(() => {
		return () => {
			if (imagePreview) {
				URL.revokeObjectURL(imagePreview);
			}
		};
	}, [imagePreview]);

	// Handler for input changes
	const handleLocalInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setter(event.target.value);
	};

	// Handler for industry changes with correct event type
	const handleLocalIndustryChange = (event: ChipsChangeEvent) => {
		const industryValues = event.value ?? [];
		setLocalIndustry(industryValues);
		saveChanges(); // Save changes whenever the industry chips are updated
	};

	// Handler for image upload
	const handleImageChange = (event: FileUploadHandlerEvent) => {
		const file = event.files[0];
		if (file) {
			const imageUrl = URL.createObjectURL(file);
			setImagePreview(imageUrl);
			setPrimarySettings({
				...primarySettings,
				profilePictureFile: file,
				profilePicturePath: imageUrl,
			});
		}
	};

	// Copy profile link to clipboard
	const copyToClipboard = () => {
		if (username) {
			const clipboardContent = 'member.webxite.my/p/'+ username
			navigator.clipboard.writeText(clipboardContent);
		}
	};

	// Function to save local states to primarySettings
	const saveChanges = () => {
		setPrimarySettings({
			...primarySettings,
			name: localName,
			designation: localDesignation,
			bio: localBio,
			mobile: localMobile,
			email: localEmail,
			industry: localIndustry,
		});
	};

	return (
		<div className="primary-content">
			<div className="profile-picture-container">
				<img
					src={imagePreview || "path_to_default_profile_picture.jpg"}
					className="custom-avatar"
					alt="Profile"
				/>
				<FileUpload
					mode="basic"
					name="profilePicture"
					accept="image/*"
					customUpload
					auto
					uploadHandler={handleImageChange}
					chooseLabel="Change Profile Picture"
				/>
				<Button
					label="Remove Profile Picture"
					icon="pi pi-trash"
					className="p-button-danger p-button-outlined p-mt-3 rounded-2"
					onClick={() => {
						setPrimarySettings({
							...primarySettings,
							profilePicturePath: null,
						});
						setImagePreview(null);
					}}
				/>
			</div>
			<div className="profile-basic-information-container">
				<div className="p-field p-fluid">
					<label htmlFor="name">Name<span className="required-asterisk">*</span></label>
					<InputText
						className="rounded-2"
						id="name"
						name="name"
						value={localName}
						onChange={handleLocalInputChange(setLocalName)}
						onBlur={saveChanges}
					/>
				</div>

				<div className="p-field p-fluid">
					<label htmlFor="designation">Designation<span className="required-asterisk">*</span></label>
					<InputText
						className="rounded-2"
						id="designation"
						name="designation"
						value={localDesignation}
						onChange={handleLocalInputChange(setLocalDesignation)}
						onBlur={saveChanges}
					/>
				</div>

				<div className="p-field p-fluid">
					<label htmlFor="mobile">Mobile Number<span className="required-asterisk">*</span></label>
					<InputText
						className="rounded-2"
						id="mobile"
						name="mobile"
						value={localMobile}
						onChange={handleLocalInputChange(setLocalMobile)}
						onBlur={saveChanges}
					/>
				</div>

				<div className="p-field p-fluid">
					<label htmlFor="email">Email Address<span className="required-asterisk">*</span></label>
					<InputText
						className="rounded-2"
						id="email"
						name="email"
						disabled={true}
						value={localEmail}
						onChange={handleLocalInputChange(setLocalEmail)}
						onBlur={saveChanges}
					/>
				</div>

				{/*<div className="p-field p-fluid">
					<label htmlFor="industry" style={{ color: '#c7c7c7' }}>
						Industry (5 Max.)
					</label>
					<Chips
						id="industry"
						className="rounded-2"
						value={localIndustry}
						onChange={handleLocalIndustryChange}
						placeholder="Add an industry"
						separator={','}
						max={5}
						onBlur={saveChanges}
					/>
				</div>*/}

				<div className="p-field p-fluid">
					<label htmlFor="bio">Bio</label>
					<InputTextarea
						className="rounded-2"
						id="bio"
						name="bio"
						value={localBio}
						onChange={handleLocalInputChange(setLocalBio)}
						onBlur={saveChanges}
						rows={5}
						maxLength={400}
					/>
				</div>

				<div className="profile-link-container">
					<div className="p-field p-fluid">
						<label htmlFor="profileLink">Profile link</label>
						<div className="p-inputgroup">
							<span
								className="p-inputgroup-addon"
								style={{
									borderTopLeftRadius: '5px',
									borderBottomLeftRadius: '5px',
								}}
							>
								member.webxite.my/p/
							</span>
							<InputText
								id="profileLink"
								name="profileLink"
								value={username}
								readOnly
							/>
							<Button
								icon="pi pi-copy"
								className="p-button-secondary copy-button"
								onClick={copyToClipboard}
								tooltip="Copy to clipboard"
								tooltipOptions={{position: 'top'}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrimaryContent;
