import React from 'react';
import './WidgetsContent.css';
import {DNDWidget} from '../../../Components/Tree/Tree.story';
import {TreeItems} from "../../../Components/Tree/types";

interface WidgetsContentProps {
	items: TreeItems;
	setItems: React.Dispatch<React.SetStateAction<TreeItems>>;
}

const WidgetsContent: React.FC<WidgetsContentProps> = ({ items, setItems }) => {
	return (
		<div className="widgets-content">
			<DNDWidget
				items={items}
				setItems={setItems}
			/>
		</div>
	);
};

export default WidgetsContent;
