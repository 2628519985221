import React, {forwardRef, HTMLAttributes} from 'react';
import classNames from 'classnames';
import {Action, Handle, Remove} from '../Utility';
// @ts-ignore
import styles from './TreeItem.module.css';

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
	childCount?: number,
	clone?: boolean,
	collapsed?: boolean,
	depth: number,
	disableInteraction?: boolean,
	disableSelection?: boolean,
	ghost?: boolean,
	handleProps?: any,
	indicator?: boolean,
	indentationWidth: number,
	value: string,
  widget?: React.ReactNode,

	onCollapse?(): void,

	onRemove?(): void,

	wrapperRef?(node: HTMLLIElement): void,
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
	(
		{
			childCount,
			clone,
			depth,
			disableSelection,
			disableInteraction,
			ghost,
			handleProps,
			indentationWidth,
			indicator,
			collapsed,
			onCollapse,
			onRemove,
			style,
			value,
			wrapperRef,
			widget,
			...props
		},
		ref
	) => {

		const getWidgetTitle = (value: string | undefined): string => {
			if (!value) return ''; // Ensure there is a value to check

			// Find the index of the "-" character in the value
			const indexOfDash = value.indexOf('-');

			if (indexOfDash !== -1) {
				// If found, remove the "-" character and everything after it
				return value.substring(0, indexOfDash).trim(); // `trim` to remove any leading or trailing whitespace
			}

			// If no "-" character is found, return the original value
			return value;
		};

		return (
			<li
				className={classNames(
					styles.Wrapper,
					clone && styles.clone,
					ghost && styles.ghost,
					indicator && styles.indicator,
					disableSelection && styles.disableSelection,
					disableInteraction && styles.disableInteraction
				)}
				ref={wrapperRef}
				style={
					{
						'--spacing': `${indentationWidth * depth}px`,
					} as React.CSSProperties
				}
				{...props}
			>
				<div className={styles.TreeItem} ref={ref} style={style}>
					<Handle {...handleProps} />
					{onCollapse && (
						<Action
							onClick={onCollapse}
							className={classNames(
								styles.Collapse,
								collapsed && styles.collapsed
							)}
						>
							{collapseIcon} {/* Icon is handled separately */}
						</Action>
					)}
					<span className={styles.Text}>{getWidgetTitle(value)}</span>
					{!clone && onRemove && <Remove style={{float: "right"}} onClick={onRemove} />}
					{clone && childCount && childCount > 1 ? (
						<span className={styles.Count}>{childCount}</span>
					) : null}
					<div
						className={""}
						style={
							{
								marginLeft: '32px',
								marginTop: '10px'
							}
						}
					>
						{widget}
					</div>
				</div>
			</li>
		);
	}
);

const collapseIcon = (
	<svg width="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 41">
		<path d="M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z" />
	</svg>
);