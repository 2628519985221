import React from 'react';
import { InputText } from "primereact/inputtext";
import { WidgetProps } from '../WidgetProperties';

// Define specific type for the value of ContactWidget
export interface ContactWidgetValue {
	formTitle: string;
	email: string;
}

// Use WidgetProps with the specific value type
const ContactWidget: React.FC<WidgetProps<ContactWidgetValue>> = ({ value, onChange }) => {
	return (
		<div>
			<InputText
				placeholder="Contact Form Title"
				value={value.formTitle || ''}
				onChange={(e) => onChange('formTitle', e.target.value)}
			/>
			<InputText
				placeholder="Contact Email"
				value={value.email || ''}
				onChange={(e) => onChange('email', e.target.value)}
			/>
		</div>
	);
};

export default ContactWidget;
