import React from 'react';

import './HeaderWidget.css'

interface HeaderWidgetProps {
	headerText: string;
}

const HeaderWidget: React.FC<HeaderWidgetProps> = ({headerText}) => {
	return (
		<div className="header-widget">
			<h2>{headerText}</h2>
		</div>
	);
};

export default HeaderWidget;