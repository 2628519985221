import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { Button } from 'primereact/button';
import './TabMenu.css';

interface TabMenuProps {
	tabsContent: Record<string, React.ReactNode>;
}

const TabMenu: React.FC<TabMenuProps> = ({ tabsContent }) => {
	const [activeTab, setActiveTab] = useState<string>('Primary');
	const underlineRef = useRef<HTMLDivElement | null>(null);
	const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
	const sectionRefs = useRef<Record<string, HTMLElement | null>>({});
	const tabs = Object.keys(tabsContent);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setActiveTab(entry.target.id.charAt(0).toUpperCase() + entry.target.id.slice(1));
					}
				});
			},
			{ threshold: .8 }
		);

		tabs.forEach((tab) => {
			const section = sectionRefs.current[tab.toLowerCase()];
			if (section) {
				observer.observe(section);
			}
		});

		return () => {
			observer.disconnect();
		};
	}, [tabs]);

	useEffect(() => {
		if (activeTab) {
			const activeIndex = tabs.indexOf(activeTab);
			const currentTabRef = tabRefs.current[activeIndex];
			if (currentTabRef && underlineRef.current) {
				const tabRect = currentTabRef.getBoundingClientRect();
				const parentRect = (currentTabRef.parentNode as HTMLElement)?.getBoundingClientRect();

				if (parentRect) {
					underlineRef.current.style.left = `${tabRect.left - parentRect.left}px`;
					underlineRef.current.style.width = `${tabRect.width}px`;
				}
			}
		}
	}, [activeTab, tabs]);

	const handleClick = (tab: string) => {
		setActiveTab(tab);
		const tabElement = document.getElementById(tab.toLowerCase());
		if (tabElement) {
			$('html, body').animate(
				{
					scrollTop: $(tabElement).offset().top,
				},
				500
			);
		}
	};

	return (
		<>
			<div className="tab-menu">
				{tabs.map((tab, index) => (
					<Button
						key={tab}
						ref={(el) => (tabRefs.current[index] = el ? (el as unknown as HTMLButtonElement) : null)}
						className={`tab-button ${activeTab === tab ? 'active' : ''}`}
						onClick={() => handleClick(tab)}
					>
						{tab}
					</Button>
				))}
				<div className="underline" ref={underlineRef}></div>
			</div>

			<div className="tab-content">
				{tabs.map((tab) => (
					<section
						className="py-5"
						id={tab.toLowerCase()}
						ref={(el) => (sectionRefs.current[tab.toLowerCase()] = el)}
						key={tab}
					>
						<h2>{tab}</h2>
						{tabsContent[tab]}
					</section>
				))}
			</div>
		</>
	);
};

export default TabMenu;
