import React from 'react';

interface MobileWidgetProps {
	mobileNumber: string;
}

const MobileWidget:React.FC<MobileWidgetProps> = ({mobileNumber}) => {
	return (
		<div className="widget">
			<div className="widget-content row">
				<div className="col-1 p-0 d-flex justify-content-center align-items-center">
					<i
						className="pi pi-phone icon m-0"
						style={{fontSize: '15px'}}
					/>
				</div>
				<div className="col-11 p-0 d-flex align-items-center">
					<span className="content">{mobileNumber}</span>
				</div>
			</div>
		</div>
	);
};

export default MobileWidget;