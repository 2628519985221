import React, {useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {
  Announcements,
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  KeyboardSensor,
  MeasuringStrategy,
  Modifier,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {arrayMove, SortableContext, verticalListSortingStrategy,} from '@dnd-kit/sortable';

import {
  buildTree,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  removeItem,
  setProperty,
} from './utilities';

import {
  CompanyWidget,
  ContactWidget,
  EmailWidget,
  FileWidget,
  HeaderWidget,
  LinkWidget,
  MapWidget,
  MobileWidget,
  SocialWidget,
  SortableTreeItem,
  TextWidget,
  VideoWidget,
} from './components';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import type {FlattenedItem, SensorContext, TreeItem, TreeItems} from './types';
import {sortableTreeKeyboardCoordinates} from './keyboardCoordinates';
import {CSS} from '@dnd-kit/utilities';
import {Dropdown} from "primereact/dropdown";
import {HeaderWidgetValue} from "./components/Widget/HeaderWidget/HeaderWidget";
import {CompanyWidgetValue} from "./components/Widget/CompanyWidget/CompanyWidget";
import {TextWidgetValue} from "./components/Widget/TextWidget/TextWidget";
import {MobileWidgetValue} from "./components/Widget/MobileWidget/MobileWidget";
import {EmailWidgetValue} from "./components/Widget/EmailWidget/EmailWidget";
import {LinkWidgetValue} from "./components/Widget/LinkWidget/LinkWidget";
import {FileWidgetValue} from "./components/Widget/FileWidget/FileWidget";
import {SocialWidgetValue} from "./components/Widget/SocialWidget/SocialWidget";
import {MapWidgetValue} from "./components/Widget/MapWidget/MapWidget";
import {VideoWidgetValue} from "./components/Widget/VideoWidget/VideoWidget";
import {ContactWidgetValue} from "./components/Widget/ContactWidget/ContactWidget";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({transform}) {
    return [      {opacity: 1, transform: CSS.Transform.toString(transform.initial)},
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({active}) {
    active.node.animate([{opacity: 0}, {opacity: 1}], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

interface SortableTreeProps {
  collapsible?: boolean;
  items: TreeItems;
  setItems: React.Dispatch<React.SetStateAction<TreeItems>>;
  indicator?: boolean;
  removable?: boolean;
  indentationWidth?: number;
}

export function SortableTree({
  collapsible,
  indicator = false,
  indentationWidth = 50,
  removable,
  items,
  setItems,
}: SortableTreeProps) {
  const [newItemType, setNewItemType] = useState<{ name: string; type: string } | null>(null);
  // const [items, setItems] = useState(() => defaultItems);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [modalVisible, setModalVisible] = useState(false); // State to control modal visibility
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const itemTypes = [
    {name: "Container", type: "Container"},
    {name: "Header Field", type: "Header"},
    {name: "Company Field", type: "Company"},
    {name: "Text Field", type: "Text"},
    {name: "Mobile Field", type: "Mobile"},
    {name: "Email Field", type: "Email"},
    {name: "Custom Link", type: "Link"},
    // {name: "Social Field", type: "Social"},
    // {name: "File Upload", type: "FileUpload"},
    // {name: "Google Maps", type: "Maps"},
    // {name: "Video Embed", type: "Videos"},
    // {name: "Contact Form", type: "ContactForm"},
    // todo: complete remaining widgets
  ]

  const addNewItem = () => {
    if (!newItemType) return;

    const currentDateTime = new Date().toISOString();
    const randomInt = Math.floor(Math.random() * 101);
    const hash = btoa((currentDateTime + randomInt).toString()).replace(/[^a-zA-Z0-9]/g, '');

    const newItem = {
      id: `${newItemType.name}-${hash}`,
      type: newItemType.type,
      children: [],
      data: {}
    };

    setItems((prevItems) => [...prevItems, newItem]);
    setModalVisible(false);
  };

  const handleWidgetChange = (id: UniqueIdentifier, field: string, newData: string) => {
    const updateItems = (items: TreeItems): TreeItems => {
      return items.map((item) => {
        if (item.id === id) {
          // Update the data for the matching item
          return {
            ...item,
            data: {
              ...item.data,
              [field]: newData,
            },
          };
        } else if (item.children && item.children.length > 0) {
          // Recursively update children if they exist
          return {
            ...item,
            children: updateItems(item.children), // Recursively update children
          };
        }
        return item; // Return unchanged item
      });
    };

    setItems((prevItems) => updateItems(prevItems)); // Update state
  };

  const findItemById = (items: TreeItem[] | undefined, id: UniqueIdentifier): TreeItem | undefined | void => {
    if (!items) return;

    for (const item of items) {
      if (item.id === id) {
        return item;
      }
      if (item.children) {
        const found = findItemById(item.children, id);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  const getWidget = (type: string, id: UniqueIdentifier): React.ReactNode | null => {
    const item = findItemById(items, id); // Use the new function to find the item
    const value = item?.data || {};

    switch (type) {
      case "Container":
        return <></>;
      case "Header":
        return (
          <HeaderWidget
            value={value as HeaderWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      case "Company":
        return (
          <CompanyWidget
            value={value as CompanyWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      case "Text":
        return (
          <TextWidget
            value={value as TextWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      case "Mobile":
        return (
          <MobileWidget
            value={value as MobileWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      case "Email":
        return (
          <EmailWidget
            value={value as EmailWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      case "Link":
        return (
          <LinkWidget
            value={value as LinkWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      case "FileUpload":
        return (
          <FileWidget
            value={value as FileWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      case "Social":
        return (
          <SocialWidget
            value={value as SocialWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      case "Maps":
        return (
          <MapWidget
            value={value as MapWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      case "Videos":
        return (
          <VideoWidget
            value={value as VideoWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      case "ContactForm":
        return (
          <ContactWidget
            value={value as ContactWidgetValue} // Cast to specific type
            onChange={(field, newData) => handleWidgetChange(id, field, newData)}
          />
        );
      default:
        return <div style={{ color: "red" }}>Invalid</div>;
    }
  };


  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);

    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, {children, collapsed, id}) =>
        // @ts-ignore
        collapsed && children.length ? [...acc, id] : acc,
      []
    );

    return removeChildrenOf(
      flattenedTree,
      // @ts-ignore
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, items]);
  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null;
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const sortedIds = useMemo(() => flattenedItems.map(({id}) => id), [
    flattenedItems,
  ]);
  const activeItem = activeId
    ? flattenedItems.find(({id}) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const announcements: Announcements = {
    onDragStart({active}) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({active, over}) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id);
    },
    onDragOver({active, over}) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id);
    },
    onDragEnd({active, over}) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id);
    },
    onDragCancel({active}) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  return (
    <DndContext
      accessibility={{announcements}}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(({id, type, children, collapsed, depth}) => (
          <SortableTreeItem
            key={id}
            id={id}
            // @ts-ignore
            value={id} // todo: change it to user-defined name
            widget={getWidget(type, id)} // Pass both type and id
            depth={id === activeId && projected ? projected.depth : depth}
            indentationWidth={indentationWidth}
            indicator={indicator}
            collapsed={Boolean(collapsed && children.length)}
            onCollapse={
              collapsible && children.length
                ? () => handleCollapse(id)
                : undefined
            }
            onRemove={removable ? () => handleRemove(id) : undefined}
          />
        ))}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(items, activeId) + 1}
                value={activeId.toString()}
                indentationWidth={indentationWidth}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
      <button
        onClick={() => setModalVisible(true)}
        style={
          {
            marginTop: '10px',
            width: '100%',
            borderRadius: '25px',
            fontWeight: 'bold'
          }
      }
      >
        Add New Item
      </button>

      {/* Modal for entering new item ID */}
      <Dialog
        header="Add New Item"
        visible={modalVisible}
        style={{
          width: '40vw',
          minWidth: '320px',
        }}
        footer={
          <div>
            <Button className={"p-button-secondary"} label="Cancel" icon="pi pi-times" onClick={() => setModalVisible(false)} />
            <Button className={"p-button-primary"} label="Add" icon="pi pi-check" onClick={addNewItem} />
          </div>
        }
        onHide={() => setModalVisible(false)}
      >
        <Dropdown
          value={newItemType}
          onChange={(e) => setNewItemType(e.target.value)}
          options={itemTypes}
          placeholder="Select a Item Type"
          optionLabel={"name"}
        />
      </Dialog>
    </DndContext>
  );

  function handleDragStart({active: {id: activeId}}: DragStartEvent) {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = flattenedItems.find(({id}) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({delta}: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({over}: DragOverEvent) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({active, over}: DragEndEvent) {
    resetState();

    if (projected && over) {
      const {depth, parentId} = projected;
      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items))
      );
      const overIndex = clonedItems.findIndex(({id}) => id === over.id);
      const activeIndex = clonedItems.findIndex(({id}) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = {...activeTreeItem, depth, parentId};

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);

      setItems(newItems);
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  }

  function handleRemove(id: UniqueIdentifier) {
    setItems((items) => removeItem(items, id));
  }

  function handleCollapse(id: UniqueIdentifier) {
    setItems((items) =>
      setProperty(items, id, 'collapsed', (value) => {
        return !value;
      })
    );
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier
  ) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items))
      );
      const overIndex = clonedItems.findIndex(({id}) => id === overId);
      const activeIndex = clonedItems.findIndex(({id}) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId;
            previousSibling = sortedItems.find(({id}) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }
}

const adjustTranslate: Modifier = ({transform}) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
}