import React from 'react';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { WidgetProps } from '../WidgetProperties'; // Import the WidgetProps interface

// Define specific type for the value of SocialWidget
export interface SocialWidgetValue {
	platform: string;
	profileUrl?: string;
	imContact?: string; // Optional field for instant messaging contact information
}

// Use WidgetProps with the specific value type
const SocialWidget: React.FC<WidgetProps<SocialWidgetValue>> = ({ value, onChange }) => {
	// Define the options for social media platforms
	const socialMediaOptions = [
		{ label: 'Facebook', value: 'Facebook' },
		{ label: 'Twitter', value: 'Twitter' },
		{ label: 'Instagram', value: 'Instagram' },
		{ label: 'LinkedIn', value: 'LinkedIn' },
		{ label: 'WhatsApp', value: 'WhatsApp' },
		{ label: 'Telegram', value: 'Telegram' },
	];

	// Determine if the selected platform is an instant messaging service
	const isInstantMessaging = ['WhatsApp', 'Telegram'].includes(value.platform);

	return (
		<div>
			<Dropdown
				placeholder="Select Social Media"
				value={value.platform || ''}
				options={socialMediaOptions}
				onChange={(e) => onChange('platform', e.value)}
			/>

			{!isInstantMessaging && (
				<InputText
					placeholder="Profile URL"
					value={value.profileUrl || ''}
					onChange={(e) => onChange('profileUrl', e.target.value)}
				/>
			)}

			{isInstantMessaging && (
				<InputText
					placeholder="Phone Number or Username"
					value={value.imContact || ''}
					onChange={(e) => onChange('imContact', e.target.value)}
				/>
			)}
		</div>
	);
};

export default SocialWidget;
