import React from 'react';
import { InputText } from "primereact/inputtext";
import { WidgetProps } from '../WidgetProperties'; // Adjust the import path as necessary

// Define specific type for the value of CompanyWidget
export interface CompanyWidgetValue {
	companyName: string;
}

// Use WidgetProps with the specific value type
const CompanyWidget: React.FC<WidgetProps<CompanyWidgetValue>> = ({ value, onChange }) => {
	return (
		<div>
			<InputText
				placeholder="Company Name"
				value={value.companyName || ''}
				onChange={(e) => onChange('companyName', e.target.value)}
			/>
		</div>
	);
};

export default CompanyWidget;
